import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About Tesla2024" />
    <div>
      <h2>About Tesla2024</h2>
      <p>Tesla2024 is an experiment in investing. The goal of the experiment is to see what happens if rather than taking out a loan to buy a 2019 Tesla Model 3 in May 2019 with a 60 month term, I instead take the money that would have been used to pay for the car and invest it in stocks with a high growth potential. So, the idea is to take what would be the monthly payment for a Model 3 ($700) and invest that money into stocks instead. Each month I'll buy at least one TSLA share, assuming it's trading below $700 a share. My motivations for waiting are I don't need a new car right now and I feel that electric vehicles will only get better in the next five years, so I'm going to bet on the future rather than buy something I don't need now. Tesla is in an amazing position to dominate a new era of the automotive industry.</p>
      <p>For as long as I've known about Tesla, I've wanted to own one of their vehicles. I love the design, what the company stands for, and I'm a huge believer in the product. As much as I like Tesla's vehicles, I've never drove or even rode in one. It'd be so cool to do this experiment for five years and then at the end of it get to experience one for the first time in 2024. At the end of the experiment, June 2024, the money invested into these growth stocks will be used to purchase a Tesla. The year, model, and features will be entirely determined by the success of my investments. If the stocks perform poorly, I'll buy a pre-owned vehicle, if they do well, I could end up with a Model S or a higher end Model 3.</p>
      <p>This site, Tesla2024.com, is going to serve to share the results of the experiment. Every stock purchase I make will be shared and detailed here. I will also share the current performance of all investments related to the experiment so you can see if it's working or not. I will also try to regularly post thoughts on Tesla and the other companies I invest in as part of the experiment. I've a had a theory for a while now that if I really want a product, I should invest in the company rather than buying their product. This experiment is a true test that theory.</p>
      <p>Check out the <Link to="rules">official rules page</Link> for more specifics on the experiment.</p>
    </div>
    
  </Layout>
)

export default About